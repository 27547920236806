.ant-tabs-nav {
  :global {
    width: 100% !important;
    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
    }
    .ant-tabs-tab-active {
      background-color: white !important;
    }
    .ant-tabs-nav > div:nth-of-type(1) {
      width: 100% !important;
    }
  }
}

.ant-btn:hover,
.active {
  :global {
    width: 7rem;
    border-radius: 0.2rem;
    background-color: #1890ff !important;
    color: white !important;
  }
}
